<template lang="">
<div class="loader">
	<div class="yellow"></div>
	<div class="red"></div>
	<div class="blue"></div>
	<div class="violet"></div>
</div>
</template>
<script>
export default {
  name: "Loader",
}
</script>
<style lang="scss" scoped>

 .loader {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%); 
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .loader>.yellow
{
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  animation: bounce 1.5s 0.5s linear infinite;
}
 .loader>.red
{
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  animation: bounce 1.5s 0.5s linear infinite;
}
 .loader>.blue
{
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  animation: bounce 1.5s 0.5s linear infinite;
}
 .loader>.violet
{
  width: 3vw;
  height: 3vw;
  border-radius: 100%;
  margin: 2vw;
  background-image: linear-gradient(145deg, rgba(255, 255, 255, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
  animation: bounce 1.5s 0.5s linear infinite;
}

 .yellow {
   background-color: #D2BCB5,;
 }

 .red {
   background-color: #E8DAD1;
   animation-delay: 0.1s;
 }

 .blue {
   background-color: #DDCECE;
   animation-delay: 0.2s;
 }

 .violet {
   background-color: #EEEDE8;
   animation-delay: 0.3s;
 }

 @keyframes bounce {
  0%{
     transform: scale(1);
     filter: blur(0px);
   }
  25% {
    transform: scale(0.6);
    filter: blur(3px);
  }
  50%{
     transform: scale(1);
     filter: blur(0px);
   }
  75% {
    filter: blur(3px);
    transform: scale(1.4);
  }
  100% {
    transform: scale(1);
    filter: blur(0px);
  }
 }
</style>