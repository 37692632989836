<template>
    <div class="">
        <div class="container">
            <div class="main-container">
                <TextBlock :blocks="blocks"/>
                <Card  :src="imageUrl"></Card>
                <Skretch v-if="scratch && runScretch"
                         @updateBalloon="updateBalloon"
                         @updateConfetti="updateConfetti"
                         @updateFireworks="updateFireworks"
                         @updateBubbles="updateBubbles"
                         @updateStars="updateStars"
                         @updateFontain="updateFontain"
                         @updateColored="updateColored"
                         @updateMagic="updateMagic"
                         :position="position" :image="scratchUrl"></Skretch>
                <Balloon v-if="balloon && runBalloon || isStartBalloon"></Balloon>
                <Confetti v-if="confetti && runConfetti || isStartConfetti"></Confetti>
                <CustomParticles v-if="fireworks && runFireworks|| isStartFireworks"></CustomParticles>
                <CustomBubbles v-if="bubbles && runBubbles || isStartBubbles && isMounted"></CustomBubbles>
                <CustomStars v-if="stars && runStars || isStartStars && isMounted"></CustomStars>
                <CustomFontain v-if="fontain && runFontain || isStartFontain && isMounted"></CustomFontain>
                <CustomColored v-if="colored && runColored || isStartColored && isMounted"></CustomColored>
                <CustomMagic v-if="magic && runMagic || isStartMagic && isMounted"></CustomMagic>
            </div>
            <div class="chouse-effect">
<!--                <Button type="button" class="btn-item d-flex justify-content-center align-items-center js-copy-link">-->
<!--                    <span class="me-2">{{ parseLanguage.content.txt.share }}</span>-->
<!--                    <span>-->
<!--                        <img src="/images/share.svg" alt="share">-->
<!--                    </span>-->
<!--                </Button>-->
                <Button v-if="scratch || balloon || confetti ||colored || fontain ||fireworks||bubbles||stars||magic" type="button" class="btn-item btn-item--outlined" @press="updateSkratch">
                    {{ parseLanguage.content.txt.try_again }}
                </Button>

                <div class="mt-2 code-container" id="code-container">

                </div>
            </div>
        </div>
    </div>
</template>



<script>
import TextBlock from "./blocks";
import Card from "./card.vue"
import Confetti from "./confetti.vue"
import Balloon from "./balloon.vue"
import Skretch from "./scretch.vue"
import Custom from "./custom-text.vue"
import Button from "./button.vue"
import CustomParticles from "./particles.vue"
import CustomBubbles from "./bubbles.vue"
import CustomStars from "./stars.vue"
import CustomFontain from "./fontain.vue"
import CustomColored from "./colored.vue"
import CustomMagic from "./magic.vue"


export default {
    name: 'Builder',
    components: {
        Card,
        Skretch,
        Custom,
        Button,
        Confetti,
        Balloon,
        CustomParticles,
        CustomBubbles,
        CustomStars,
        CustomFontain,
        CustomColored,
        CustomMagic,
        TextBlock
    },
    data() {
        return {
            blocks:[],
            runBalloon: false,
            runConfetti: false,
            runFireworks: false,
            runBubbles: false,
            runStars: false,
            runFontain: false,
            runColored: false,
            runMagic: false,
            runScretch: true,
            isMounted: false,
            window: {
                width: 0,
                height: 0
            },
        }
    },
    props: [
        'srcIm',
        'srcSc',
        'effects',
        'language',
    ],
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted() {
        this.isMounted=true;
        let ratio = this.checkWindowSize();
        this.blocks = this.savedBlocks.map((savedBlock)=>{
            return {
                fontFamily:savedBlock.fontFamily,
                fontSize:Number(savedBlock.fontSize.substring(0, savedBlock.fontSize.length - 2))*ratio+'px',
                inputText: savedBlock.inputText,
                textPosition:savedBlock.textPosition,
                inputPlaceholder:savedBlock.inputText,
                color:savedBlock.color,
                selected:savedBlock.selected,
                id:savedBlock.id,
                transform: { x: savedBlock.transform.x*ratio,
                    y:savedBlock.transform.y*ratio,
                    width:savedBlock.transform.width*ratio,
                    height:savedBlock.transform.height*ratio,
                    rotation:savedBlock.transform.rotation,
                },
                active: false,
            }
        });
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    computed: {
        parseLanguage() {
            return JSON.parse(this.language)
        },
        isStartBalloon() {
            if (this.scratch === false && this.balloon === true) {
                return true
            }
            return false;
        },
        isStartConfetti() {
            if (this.scratch === false && this.confetti === true) {
                return true
            }
            return false;
        },
        isStartFireworks() {
            if (this.scratch === false && this.fireworks === true) {
                return true
            }
            return false;
        },
        isStartBubbles() {
            if (this.scratch === false && this.bubbles === true) {
                return true
            }
            return false;
        },
        isStartStars() {
            if (this.scratch === false && this.stars === true) {
                return true
            }
            return false;
        },
        isStartFontain() {
            if (this.scratch === false && this.fontain === true) {
                return true
            }
            return false;
        },
        isStartColored() {
            if (this.scratch === false && this.colored === true) {
                return true
            }
            return false;
        },
        isStartMagic() {
            if (this.scratch === false && this.magic === true) {
                return true
            }
            return false;
        },
        savedBlocks() {
            let parseEffects = JSON.parse(this.effects);
            let parseText = JSON.parse(parseEffects.text);
            if (parseText === "" || parseText === undefined){
                return [];
            }
            return parseText;
        },
        scratch() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isSkretch === 'false' || parseEffect.isConfetti === undefined) {
                return false;
            }
            return true;
        },
        balloon() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isBalloons === 'false' || parseEffect.isBalloons === undefined) {
                return false;
            }
            return true;
        },
        confetti() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isConfetti === 'false' || parseEffect.isConfetti === undefined) {
                return false;
            }
            return true;
        },
        fireworks() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isFireworks === 'false' || parseEffect.isFireworks === undefined) {
                return false;
            }
            return true;
        },
        bubbles() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isBubbles === 'false' || parseEffect.isBubbles === undefined) {
                return false;
            }
            return true;
        },
        stars() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isStars === 'false' || parseEffect.isStars === undefined) {
                return false;
            }
            return true;
        },
        fontain() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isFontain === 'false' || parseEffect.isFontain === undefined) {
                return false;
            }
            return true;
        },
        colored() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isColored === 'false' || parseEffect.isColored === undefined) {
                return false;
            }
            return true;
        },
        magic() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isMagic === 'false' || parseEffect.isMagic === undefined) {
                return false;
            }
            return true;
        },
        imageUrl() {
            return this.srcIm
        },
        scratchUrl() {
            return this.srcSc
        },
        position() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.skretchPosition === '' || parseEffect.skretchPosition === undefined) {
                return 0;
            }
            return Number(parseEffect.skretchPosition);
        },
    },
    methods: {
        checkWindowSize(){
            if (this.window.width < 992){
                return 0.875
            } else {
                return 1
            }
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            let ratio = this.checkWindowSize();
            this.blocks = this.savedBlocks.map((savedBlock)=>{
                return {
                    fontFamily:savedBlock.fontFamily,
                    fontSize:Number(savedBlock.fontSize.substring(0, savedBlock.fontSize.length - 2))*ratio+'px',
                    textPosition:savedBlock.textPosition,
                    inputText: savedBlock.inputText,
                    color:savedBlock.color,
                    selected:savedBlock.selected,
                    id:savedBlock.id,
                    transform: { x: savedBlock.transform.x*ratio,
                        y:savedBlock.transform.y*ratio,
                        width:savedBlock.transform.width*ratio,
                        height:savedBlock.transform.height*ratio,
                        rotation:savedBlock.transform.rotation,
                    },
                    active: false,
                }
            });
        },
        updateBalloon() {
            this.runBalloon = true;
        },
        updateConfetti() {
            this.runConfetti = true;
        },
        updateSkratch() {
            location.reload()
        },
        updateFireworks() {
            this.runFireworks = true;
        },
        updateBubbles() {
            this.runBubbles = true;
        },
        updateStars() {
            this.runStars = true;
        },
        updateFontain() {
            this.runFontain = true;
        },
        updateColored() {
            this.runColored = true;
        },
        updateMagic() {
            this.runMagic = true;
        },
    },

}
</script>

<style lang="scss" scoped>
.text {
    position: absolute;
    text-align: center;
    max-width: 70%;
    height: 96%;
    line-height: 1.59;

    @media screen and (min-width: 576px) {
        line-height: 1.59;
        height: 93%;
    }

    @media screen and (min-width: 992px) {
        height: 100%;
        line-height: 1.67;
    }

}

.card {
    max-width: 1200px;
    margin: 0 auto;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 992px) {
        flex-direction: row;
    }
}

.main-container {
    user-select: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 477px;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    @media screen and (min-width: 992px) {
        width: 400px;
        height: 545px;
        margin-bottom: 0;
        margin-left: 0;


    }
}

.chouse-effect {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    @media screen and (min-width: 992px) {
        margin-left: 40px;
    }
}

.public {
    display: flex;
}

.btn-item {
    @media screen and (max-width: 576px) {
        width: 270px !important;
    }
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #353535;
    width: 370px !important;
    height: 51px !important;
    min-width: fit-content !important;
    background-color: #D1D6D8 !important;
    border: 1px solid !important;
    border-radius: 5px !important;
    border-color: #D1D6D8 !important;
    margin-bottom: 15px !important;
    transition: all 250ms linear !important;

    &:hover,
    &:focus {
        box-shadow: 0px 10px 20px rgba(124, 124, 124, 0.25);
    }

    &--outlined {
        background-color: transparent !important;
        transition: all 250ms linear !important;

        &:hover,
        &:focus {
            background-color: transparent !important;
            background-color: #D1D6D8 !important;
            box-shadow: 0px 0px 0px;
        }
    }

    &--submit {
        width: 100% !important;
    }
}

.public-btn-item .social {
    transform: scale(1);
    transition: all 250ms linear;
    color: #c78f7c;
    fill: currentColor;
}
</style>

