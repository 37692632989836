<template>
  <button 
  v-bind:type="type" 
  :disabled=disabled
  @click="handleClick">
    <slot></slot>
  </button>
</template>
<script>

export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'button'
    },
    active: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('press');
    }
  },

}
</script>
<style lang="scss">
    .button-chouse {
      margin-bottom: 10px;
    }
</style>