/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('datatables.net');
require('../admin/js/notify/bootstrap-notify.min')
require('../admin/js/notify/notify-script')
require('jquery-validation');
require('jquery-mask-plugin');
require('select2/dist/js/select2.full');

const Swal = require('sweetalert2')

window.jQuery.validator.setDefaults({
    errorClass: 'input-error',
});


import Vue from 'vue'
import Vue2Editor from "vue2-editor";
import Particles from "vue2-particles";

Vue.use(Particles);
Vue.use(Vue2Editor);

Vue.component('builder-card', require('./components/builder-card.vue').default);
Vue.component('present-card', require('./components/present-card.vue').default);
Vue.component('builder-customer-card', require('./components/builder-customer-card.vue').default);
Vue.component('loader', require('./components/loader.vue').default);
if($('#app').length){
    const app = new Vue({
        el: '#app',
    });
}

// import { loadFull } from "tsparticles";

// const particlesInit = async (engine) => {
//     await loadFull(engine);
// }

// const particlesLoaded = async (container) => {
// }

$(document).ready(function () {
    $('#sidebar-toggle').on('change', function (e) {
        e.preventDefault();
        if ($(this).is(':checked')) {
            $('.page-sidebar').show(200)
            $('.page-body').css('transition-duration', '1s').css('margin-left', '250px')
        } else {
            $('.page-sidebar').hide(200)
            $('.page-body').css('transition-duration', '1s').css('margin-left', '0')
        }
    })
});

window.notify = function (text, type = 'success') {
    if (type === 'error') {
        type = 'danger';
    }
    $.notify({
            message:text
        },
        {
            type:type,
            allow_dismiss:true,
            newest_on_top:false ,
            mouse_over:false,
            showProgressbar:true,
            spacing:10,
            timer:1000,
            placement:{
                from:'top',
                align:'right'
            },
            offset:{
                x:30,
                y:30
            },
            delay:1000 ,
            z_index:10000,
            animate:{
                enter:'animated bounceIn',
                exit:'animated bounceOut'
            }
        });
}
