
const skretchingEffect = (option, url) => {
  let isDrawing = false;
  let lastPoint = null;
  let container = document.getElementById('js-container');
  let canvas = document.getElementById('js-canvas');
  let canvasWidth = container.offsetWidth;
  let canvasHeight = container.offsetHeight;

  canvas.setAttribute("width", canvasWidth);
  canvas.setAttribute("height", canvasHeight);
  var ctx = canvas.getContext('2d');
  const image = new Image();
  const brush = new Image();
  // base64 Workaround because Same-Origin-Policy
  image.src = url ? url:'/images/Herz.png';
  image.onload = function () {
    const x = canvasWidth;
    const y = canvasHeight;
    ctx.drawImage(image, 0, 0, x, y);
  };
  brush.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAPCAYAAADzun+cAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIzSURBVHgBvZRLa9VQFIX3aXJv683ttVREfKBSnAmKDwQnIo4UnCkoCE78Cf4XUQSHzgURnCmiUgXFmYgVim+r1l4bW2MerkVW4jFcq1LaDR85OTk5a++1T2K2OjEk6ghsZaMrcpCBws+kGS3gNOY1FK6xobOlYw3YqGsq8SqcX7ETbWXnlAT5AUbBuK5bNbfoJVV4e7GgtRLls+9gzEqHh5lI6IlSMAELmhvVfahsJ8BBsE5zV7WWz0Y0x/sN4BDYA+6BJ+AbiCXMoqwSLjRmNl91v6AsZ6209jzYDa6Au1pzSck91rMOuA1egs9gGjzz3MgqS+qKe73ecL/fL7wkxmXpCXAAHFXmzHoGnJUoNz4FdsqlTeACuKl1EZi331tRn+quc24sTdPZyrowDPfleX4G43MSphM3wDW9swXckoW0fxf4IKfYtlfgHfjYFPWFE4jOeVZAM2eWb8Ed0AcPwEVtvh9sB8fAZnDcysP2XImHWjeva94UHvRJDEVRtD6OY76AYdTFuKNNaPVpcFJVUXREidHWacFDdV/VpwM06h77UUBoUfZgGNOVrjZm//Zaebp5eKbANittnQRvtO6Rla35Y/jCgRxghnP26+gz69egp/mn4JM2vi5RVnwkCIIvWZY9lDP2r8JZ41ndb1bfbrcnkiShtTy5tPKyqmYbdnDcarUmIfzeyn4vGX/77VURKMnDVh6qKb3LytkWtoJfxIsBBSxLuLm+IzH+Evk5zdh/xk9lVrE9MEyR0gAAAABJRU5ErkJggg=='
  canvas.addEventListener('mousedown', handleMouseDown, false);
  canvas.addEventListener('touchstart', handleMouseDown, false);
  canvas.addEventListener('mousemove', handleMouseMove, false);
  canvas.addEventListener('touchmove', handleMouseMove, false);
  canvas.addEventListener('mouseup', handleMouseUp, false);
  canvas.addEventListener('touchend', handleMouseUp, false);

  function distanceBetween(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
  }

  function angleBetween(point1, point2) {
    return Math.atan2( point2.x - point1.x, point2.y - point1.y );
  }

  // Only test every `stride` pixel. `stride`x faster,
  // but might lead to inaccuracy
function getFilledInPixels(stride) {
  if (!stride || stride < 1) { stride = 1; }

    var pixels   = ctx.getImageData(0, 0, canvasWidth, canvasHeight*2.06),
        pdata    = pixels.data,
        l        = pdata.length,
        total    = (l / stride),
      count = 0;
    // Iterate over all pixels
  for (var i = 0; i < l; i += stride) {
      if (parseInt(pdata[i]) === 0) {
        count++;
      }
    }
    return Math.round((count / total) * 100);
  }

  function getMouse(e, canvas) {
    var offsetX = 0, offsetY = 0, mx, my;
    if (canvas.offsetParent !== undefined) {
      do {
        offsetX += canvas.offsetLeft;
        offsetY += canvas.offsetTop;
      } while ((canvas = canvas.offsetParent));
    }
    mx = (e.pageX || e.touches[0].clientX) - offsetX;
    my = (e.pageY || e.touches[0].clientY) - offsetY;
    return {x: mx, y: my};
  }

  function handlePercentage(filledInPixels) {
    filledInPixels = filledInPixels || 0;
    if (filledInPixels > 99 && option) {
      option.$emit('updateBalloon');
      option.$emit('updateConfetti')
      option.$emit('updateFireworks')
      option.$emit('updateBubbles')
      option.$emit('updateStars')
      option.$emit('updateFontain')
      option.$emit('updateColored')
      option.$emit('updateMagic')


    }
  }

  function handleMouseDown(e) {
    isDrawing = true;
    lastPoint = getMouse(e, canvas);
    container.classList.add("new-coin")
    container.classList.remove("coin")
  }

  function handleMouseMove(e) {

    if (!isDrawing) { return; }

    e.preventDefault();
    var currentPoint = getMouse(e, canvas),
        dist = distanceBetween(lastPoint, currentPoint),
        angle = angleBetween(lastPoint, currentPoint),
      x, y;
    for (var i = 0; i < dist; i++) {
      x = lastPoint.x + (Math.sin(angle) * i)-10;
      y = lastPoint.y + (Math.cos(angle) * i)-10;
      ctx.globalCompositeOperation = 'destination-out';
      ctx.drawImage(brush, x, y);
    }

    lastPoint = currentPoint;
    handlePercentage(getFilledInPixels());
  }

  function handleMouseUp(e) {
    isDrawing = false;
    container.classList.remove("new-coin")
    container.classList.add("coin")
  }
};

export default skretchingEffect;
