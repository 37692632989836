<template lang="">
    <Particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      :options="options"
    />
</template>
<script>
import { loadFull } from "tsparticles";
export default {
  name:'CustomStars',
  data() {
    return {
      options: {
    //     fullScreen: {
    // enable: true
    //     },
   detectRetina: true,
      fpsLimit: 30,
        emitters: {
          name: "stars",
          direction: "bottom",
          size: {
            width: 0,
            height: 0
          },
          position: {
            x:0,
            y: 100
          },
          rate: {
            delay: 0.2,
            quantity: 5
          },
          life: {
            count: 50,
            duration: 0.1,
            delay: 0.2
        },

        },
  particles: {
    color: {
      value: [
        "#D2BCB5",
        "#E8DAD1",
        "#DDCECE",
        "#AEA8A8",
        "#F5C8B9",
        "#F5B9B9",
        "#ECDFE8",
        "#ECDFDF",
        "#F2EAE8",
        "#F4E2E2",
        "#E9C4C4",
        "#E4ECF1"
      ]
    },
    move: {
      angle: {
        offset: 0,
        value: 5
      },
      direction: "bottom",
      enable: true,
      out_mode: "out",
      speed: 3
    },
    number: {
      value: 0
    },
    opacity: {
      value: 1
    },
    shape: {
      type: "star"
    },
    size: {
      value: 10
    },
    roll: {
      darken: {
        enable: true,
        value: 15
      },
      enlighten: {
        enable: true,
        value: 3
      },
      enable: true,
      mode: "horizontal",
      speed: {
        min: 10,
        max: 15
      }
    },
    zIndex: {
      value: {
        min: 0,
        max: 100
      },
      opacityRate: 2,
      velocityRate: 3
    }
  }
      }
    }
  },
  methods: {
    async particlesInit (engine) {
    await loadFull(engine)
    },
    async particlesLoaded(container){
      setTimeout(function () {
 	      container.destroy();
      }, 25000)
  }
  },
}
</script>
<style lang="scss">

</style>
