<template>
  <div id="balloon-container"></div>
</template>
<script>
import createBalloons from "../effects/balloon.js"
export default {
  name: "Balloon",
  mounted() {
    createBalloons(100)
  }
}
</script>
<style lang="scss" >
  #balloon-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  box-sizing: border-box;
  z-index: 7;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  overflow: hidden;
}

.balloon {
  height: 125px;
  width: 105px;
  border-radius: 75% 75% 70% 70%;
  position: relative;
}

.balloon:before {
  content: "";
  height: 75px;
  width: 1px;
  padding: 1px;
  background-color: #fdfd96;
  display: block;
  position: absolute;
  top: 125px;
  left: 0;
  right: 0;
  margin: auto;
}

.balloon:after {
  content: "▲";
  text-align: center;
  display: block;
  position: absolute;
  color: inherit;
  top: 120px;
  left: 0;
  right: 0;
  margin: auto;
}

@keyframes float {
  from {
    transform: translateY(100vh);
    opacity: 1;
  }
  to {
    transform: translateY(-300vh);
    opacity: 0;
  }
}
</style>
