<template lang="">
    <div class="text"><slot></slot></div>
</template>

<script>
export default {
  name: "CustomText",
}
</script>

<style lang="scss">
  .text{
    z-index: 4;
    position: absolute;
    line-height: 1.59;
    text-align: center;
    max-width: 70%;
    height: 100%;
    @media screen and (max-width: 576px) {
        height: 100%;
      }
  }
</style>