<template>
    <div class="bg">
        <div class="header">
            <a href="https://jolicoon.de/" title="jolicoon.de" target="_blank">
                <img src="/images/global/logo.png" alt="logo" class="logo-jolicoon">
            </a>
        </div>
        <div v-if="!isInApiRender" class="d-flex align-items-center justify-content-center mb-4"><div class="ring text-center">!</div>
            <span class="preview-text ps-1">{{ parseLanguage.content.txt.preview }}
            </span>
        </div>
        <div class="container">
            <div class="main-container">
                <TextBlock :blocks="blocks"/>
                <Card  :src="imageUrl"></Card>
                <Skretch v-if="scratch && runScretch"
                         @updateBalloon="updateBalloon"
                         @updateConfetti="updateConfetti"
                         @updateFireworks="updateFireworks"
                         @updateBubbles="updateBubbles"
                         @updateStars="updateStars"
                         @updateFontain="updateFontain"
                         @updateColored="updateColored"
                         @updateMagic="updateMagic"
                         v-bind:class="{ opacity: isOpacity }" :position="position" :image="scratchUrl">
                </Skretch>
                <Balloon v-if="balloon && runBalloon || isStartBalloon">
                </Balloon>
                <Confetti v-if="confetti && runConfetti || isStartConfetti"></Confetti>
                <CustomParticles v-if="fireworks && runFireworks|| isStartFireworks && isMounted"></CustomParticles>
                <CustomBubbles v-if="bubbles && runBubbles || isStartBubbles && isMounted"></CustomBubbles>
                <CustomStars v-if="stars && runStars || isStartStars && isMounted"></CustomStars>
                <CustomFontain v-if="fontain && runFontain || isStartFontain && isMounted"></CustomFontain>
                <CustomColored v-if="colored && runColored || isStartColored && isMounted"></CustomColored>
                <CustomMagic v-if="magic && runMagic || isStartMagic && isMounted"></CustomMagic>
            </div>
            <div class="form-container">
                <form>
                    <div v-if="blocks.length" class="inputs-container mb-4">
                    <label v-if="block.inputPlaceholder!==''" v-for="block in blocks" :key="block.id" :for="block.id" style="width: 100%" class="mb-4">
                        <div class="input-label">
                            <div class="d-flex align-items-center" >
                            <span v-if="block.fieldName" class="text-muted me-1">{{ block.fieldName.charAt(0).toUpperCase() + block.fieldName.slice(1) }}:</span>
                            <div v-if="block.selected.code === 2" class="mb-2 main-text description text-muted position-relative" style="display: inline-block">
                                <img :src="'/images/global/info.svg'"
                                     class="pe-1 cursor-info absolute-info-icon" alt="fan size"
                                     data-bs-toggle="tooltip" data-bs-placement="top"
                                     :title="block.fieldLimit.textArea.row +  parseLanguage.content.txt.lines +'/ ' + block.fieldLimit.textArea.symbol+ parseLanguage.content.txt.characters_per_line+'/ '+
parseLanguage.content.txt.feel_free_to_use_enter">
                            </div>
                            </div>
                            <span v-if="block.fieldLimit && block.selected.code === 1" class="text-muted">{{ block.fieldLimit.input-block.inputText.split('').filter(c => c !== ' ').length }}  {{ parseLanguage.content.txt.characters_available }}</span>
                            <span v-else-if="block.fieldLimit && block.selected.code === 2" class="text-muted">{{ block.fieldLimit.textArea.row*block.fieldLimit.textArea.symbol-(block.inputText.split('').filter(c => c !== ' ').length-(block.fieldLimit.textArea.row-1)) }} {{ parseLanguage.content.txt.characters_available }}</span>

                        </div>
                    <div class="d-flex flex-column justify-start ml-2">
                        <div v-if="block.error" class="text-danger">{{block.error}}</div>
                        <input v-if = "block.selected.code === 1"
                               v-model="block.inputText"
                               :placeholder="block.inputPlaceholder"
                               type="text"
                               class="custom-input"
                               required="true"
                               @blur ="checkForm"
                               @input="limitInputSymbols($event, block.fieldLimit.input, block.id)"
                        />
                            <textarea v-else-if="block.selected.code === 2"
                                      :placeholder="block.inputPlaceholder"
                                      class="custom-input js-limit-rows "
                                      type="text"
                                      required="true"
                                      v-model="block.inputText"
                                      @input="limitTextAreaSymbols( $event, block.fieldLimit.textArea.row, block.fieldLimit.textArea.symbol, block.id)"
                                      data-bs-toggle="tooltip" data-bs-placement="top"
                                      @blur ="checkForm"
                            ></textarea>
                        <div v-if="block.canTextGrow"  class="d-flex align-items-center mt-2">
                            <button title="Smaller Font" type="button" class="sourceSmallerFont" @click="smallerFont( $event, block.id)">-</button>
                            <span title="Normal Font" class="textBiggerSmaller"><span>A</span><span>A</span><span>A</span></span>
                            <button title="Smaller Font" type="button" class="sourceSmallerFont" @click="biggerFont( $event, block.id)">+</button>
                        </div>
                    </div>
                    </label>
                    </div>
                    <button v-if="isInApiRender && blocks.length" type="button" class="btn-item btn-item--submit js-add-changes"
                            @click="apply">
                        {{ parseLanguage.content.txt.shopify_add_to_cart }}
                    </button>
                    <button v-if="isInApiRender && !blocks.length" type="button" class="btn-item btn-item--submit js-add-changes"
                            @click="applyInShopifyIfNoText">
                        {{ parseLanguage.content.txt.shopify_add_to_cart }}
                    </button>
                    <button v-if="!isInApiRender && blocks.length" type="button"
                            class="btn-item btn-item--submit js-add-changes" @click="apply">
                        {{ parseLanguage.content.txt.add_changes }}
                    </button>
                </form>
                <div class="chouse-effect">
                    <button v-if="!isInApiRender && !blocks.length" type="button"
                            class="btn-item d-flex justify-content-center align-items-center"
                            id="share" @click="share(urlToPostcard, $event)">
                        <span class="me-2">{{ parseLanguage.content.txt.copy_link }}</span>
                        <span>
                          <img src="/images/share.svg" alt="share">
                        </span>
                    </button>
                    <button v-if="!isInApiRender && blocks.length" type="button"
                            class="btn-item d-none justify-content-center align-items-center"
                            id="share" @click="share(urlToPostcard, $event)">
                        <span class="me-2">{{ parseLanguage.content.txt.copy_link }}</span>
                        <span>
                          <img src="/images/share.svg" alt="share">
                        </span>
                    </button>

                    <button v-if="!isInApiRender && blocks.length" type="button" class="btn-item btn-item--outlined"
                            @click="updateSkratch" id="discart">
                        {{ parseLanguage.content.txt.discard_changes }}
                    </button>
                    <Button v-if="!isInApiRender && !blocks.length && (scratch || balloon || confetti ||colored || fontain ||fireworks||bubbles||stars||magic)" type="button" class="btn-item btn-item--outlined"
                            @press="updateSkratch">
                        {{ parseLanguage.content.txt.try_again }}
                    </Button>

                </div>
            </div>
        </div>
    </div>
</template>


<script>
import TextBlock from "./blocks";
import Card from "./card.vue"
import Confetti from "./confetti.vue"
import Balloon from "./balloon.vue"
import Skretch from "./scretch.vue"
import Custom from "./custom-text.vue"
import Button from "./button.vue"
import Swal from "sweetalert2";
import CustomParticles from "./particles.vue"
import CustomBubbles from "./bubbles.vue"
import CustomStars from "./stars.vue"
import CustomFontain from "./fontain.vue"
import CustomColored from "./colored.vue"
import CustomMagic from "./magic.vue"
import skretchingEffect from "../card";


export default {
    name: 'Builder',
    components: {
        Card,
        Skretch,
        Custom,
        Button,
        Confetti,
        Balloon,
        CustomParticles,
        CustomBubbles,
        CustomStars,
        CustomFontain,
        CustomColored,
        CustomMagic,
        TextBlock
    },
    data() {
        return {
            blocks:[],
            blocksForSave:[],
            runBalloon: false,
            runConfetti: false,
            runScretch: true,
            runFireworks: false,
            runBubbles: false,
            runStars: false,
            runFontain: false,
            runColored: false,
            runMagic: false,
            isMounted: false,
            isOpacity: true,
            dataForProduct: new FormData(),
            fontSizeText: null,
            window: {
                width: 0,
                height: 0
            },
            msg: [],
        }
    },
    props: [
        'api',
        'urlToPostcard',
        'urlToBack',
        'srcIm',
        'srcSc',
        'effects',
        'language',
    ],

    computed: {
        parseLanguage() {
            return JSON.parse(this.language)
        },
        isStartBalloon() {
            if (this.scratch === false && this.balloon === true) {
                return true
            }
            return false;
        },
        isStartConfetti() {
            if (this.scratch === false && this.confetti === true) {
                return true
            }
            return false;
        },
        isStartFireworks() {
            if (this.scratch === false && this.fireworks === true) {
                return true
            }
            return false;
        },
        isStartBubbles() {
            if (this.scratch === false && this.bubbles === true) {
                return true
            }
            return false;
        },
        isStartStars() {
            if (this.scratch === false && this.stars === true) {
                return true
            }
            return false;
        },
        isStartFontain() {
            if (this.scratch === false && this.fontain === true) {
                return true
            }
            return false;
        },
        isStartColored() {
            if (this.scratch === false && this.colored === true) {
                return true
            }
            return false;
        },
        isStartMagic() {
            if (this.scratch === false && this.magic === true) {
                return true
            }
            return false;
        },
        isInApiRender() {
            if (this.api === 'false' || this.api === undefined) {
                return false;
            }
            return true;
        },
        savedBlocks() {
            let parseEffects = JSON.parse(this.effects);
            let parseText = JSON.parse(parseEffects.text);
            if (parseText === "" || parseText === undefined){
                return [];
            }
            return parseText;
        },
        scratch() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isSkretch === 'false' || parseEffect.isSkretch === undefined) {
                return false;
            }
            return true;
        },
        balloon() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isBalloons === 'false' || parseEffect.isBalloons === undefined) {
                return false;
            }
            return true;
        },
        confetti() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isConfetti === 'false' || parseEffect.isConfetti === undefined) {
                return false;
            }
            return true;
        },
        fireworks() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isFireworks === 'false' || parseEffect.isFireworks === undefined) {
                return false;
            }
            return true;
        },
        bubbles() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isBubbles === 'false' || parseEffect.isBubbles === undefined) {
                return false;
            }
            return true;
        },
        stars() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isStars === 'false' || parseEffect.isStars === undefined) {
                return false;
            }
            return true;
        },
        fontain() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isFontain === 'false' || parseEffect.isFontain === undefined) {
                return false;
            }
            return true;
        },
        colored() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isColored === 'false' || parseEffect.isColored === undefined) {
                return false;
            }
            return true;
        },
        magic() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.isMagic === 'false' || parseEffect.isMagic === undefined) {
                return false;
            }
            return true;
        },
        imageUrl() {

            return this.srcIm
        },
        scratchUrl() {
            return this.srcSc
        },
        position() {
            const parseEffect = JSON.parse(this.effects);
            if (parseEffect.skretchPosition === '' || parseEffect.skretchPosition === undefined) {
                return 0;
            }
            return Number(parseEffect.skretchPosition);
        },
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted() {
        this.isMounted=true;
                this.blocksForSave= this.savedBlocks;
                let ratio = this.checkWindowSize();
                this.blocks = this.savedBlocks.map((savedBlock)=>{
                    return {...savedBlock,
                        fontSize:Number(savedBlock.fontSize.substring(0, savedBlock.fontSize.length - 2))*ratio+'px',
                        inputText: "",
                        inputPlaceholder:savedBlock.inputText,
                        transform: { x: savedBlock.transform.x*ratio,
                            y:savedBlock.transform.y*ratio,
                            width:savedBlock.transform.width*ratio,
                            height:savedBlock.transform.height*ratio,
                            rotation:savedBlock.transform.rotation,
                        },
                        active: false,
                        error:'',
                    }
                });
        this.isOpacity = !!this.blocks.length;
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    methods: {
        smallerFont(e, selectId){
            let selectArea = this.blocks.find(({id})=>id === selectId);
            let selectAreaSave = this.blocksForSave.find(({id})=>id === selectId);
            let initualFontSize = Number(selectArea.fontSize.substring(0, selectArea.fontSize.length - 2));
            initualFontSize= initualFontSize-2;
            selectArea.fontSize=initualFontSize+'px'
            selectAreaSave.fontSize=initualFontSize+'px'
        },
        biggerFont(e, selectId){
            let parentBlockWidth = document.getElementById(selectId).offsetWidth;
            let childBlockWidth = document.getElementById(selectId).firstElementChild.offsetWidth;
            let parentBlockHeight = document.getElementById(selectId).offsetHeight;
            let childBlockHeight = document.getElementById(selectId).firstElementChild.offsetHeight;
            let selectArea = this.blocks.find(({id})=>id === selectId);
            let selectAreaSave = this.blocksForSave.find(({id})=>id === selectId);
            let initualFontSize = Number(selectArea.fontSize.substring(0, selectArea.fontSize.length - 2));
            if (parentBlockWidth<childBlockWidth || parentBlockHeight<childBlockHeight){
                return
            } else {
                initualFontSize = initualFontSize+2;
            }
            selectArea.fontSize=initualFontSize+'px'
            selectAreaSave.fontSize=initualFontSize+'px'
        },
        canChangeFontSize(parentBlockWidth,childBlockWidth,parentBlockHeight,childBlockHeight){
                    if (parentBlockWidth <= childBlockWidth || parentBlockHeight <= childBlockHeight) {
                        return false
                    } else {
                        return true
                    }
        },
        checkWindowSize(){
          if (this.window.width < 992){
              return 0.875
          } else {
              return 1
          }
        },
        checkForm (e) {
            this.blocks.forEach((block) => {
                if (block.inputText.trim()) {
                    block.error='';
                    return true
                }
                else {
                    block.error=this.parseLanguage.content.txt.field_required;
                    return false
                }
            });
        },
        limitInputSymbols(e, maxSymbols, selectId){
            let selectArea = this.blocks.find(({id})=>id === selectId);
            let text = e.currentTarget.value;
            if (text.split('').filter(c => c !== ' ').length > maxSymbols) {
                    text = text.substring(0, maxSymbols);
                }
            selectArea.inputText = text;
            let initualFontSize = Number(selectArea.fontSize.substring(0, selectArea.fontSize.length - 2));
            let parentBlockWidth = document.getElementById(selectId).offsetWidth;
            let childBlockWidth = document.getElementById(selectId).firstElementChild.offsetWidth;
            let parentBlockHeight = document.getElementById(selectId).offsetHeight;
            let childBlockHeight = document.getElementById(selectId).firstElementChild.offsetHeight;
            let selectAreaSave = this.blocksForSave.find(({id})=>id === selectId);
            if (parentBlockWidth<childBlockWidth || parentBlockHeight<childBlockHeight){
                initualFontSize = Math.floor(initualFontSize*0.9);

            } else {
                return
            }
            selectArea.fontSize=initualFontSize+'px'
            selectAreaSave.fontSize=initualFontSize+'px'
        },
        limitTextAreaSymbols(e, mawRow, maxLength, selectId){
           let selectArea = this.blocks.find(({id})=>id === selectId);
                let text = e.currentTarget.value;
                let lines = text.split("\n");
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i].split('').filter(c => c !== ' ').length > maxLength) {
                        lines[i] = lines[i].substring(0, maxLength);
                    }
                }
                while (lines.length > mawRow){
                    lines.pop();
                }
            text = lines.join("\n");
            selectArea.inputText = text;
        },
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
            let ratio = this.checkWindowSize();
            this.blocks = this.savedBlocks.map((savedBlock)=>{
                return {...savedBlock,
                    fontSize:Number(savedBlock.fontSize.substring(0, savedBlock.fontSize.length - 2))*ratio+'px',
                    inputText: this.blocks.length ? this.blocks.find( ({ id }) => id === savedBlock.id).inputText : '',
                    inputPlaceholder:savedBlock.inputText,
                    transform: { x: savedBlock.transform.x*ratio,
                        y:savedBlock.transform.y*ratio,
                        width:savedBlock.transform.width*ratio,
                        height:savedBlock.transform.height*ratio,
                        rotation:savedBlock.transform.rotation,
                    },
                }
            });

        },
        updateBalloon() {
            this.runBalloon = true;
        },
        updateConfetti() {
            this.runConfetti = true;
        },
        updateFireworks() {
            this.runFireworks = true;
        },
        updateBubbles() {
            this.runBubbles = true;
        },
        updateStars() {
            this.runStars = true;
        },
        updateFontain() {
            this.runFontain = true;
        },
        updateColored() {
            this.runColored = true;
        },
        updateMagic() {
            this.runMagic = true;
        },
        updateSkratch() {
            Swal.fire({
                title: this.parseLanguage.content.txt.sure,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#FF5370',
                cancelButtonColor: '#BCBABA',
                confirmButtonText: this.parseLanguage.content.txt.yes_discard,
                cancelButtonText: this.parseLanguage.content.txt.cancel
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.reload();
                }
            })
        },
        applyInShopifyIfNoText(){
            let vm = this;
            let setting = {
                text: '',
                isSkretch: vm.scratch === undefined ? false : vm.scratch,
                isBalloons: vm.balloon === undefined ? false : vm.balloon,
                isConfetti: vm.confetti === undefined ? false : vm.confetti,
                isFireworks: vm.fireworks === undefined ? false : vm.fireworks,
                isBubbles: vm.bubbles === undefined ? false : vm.bubbles,
                isStars: vm.stars === undefined ? false : vm.stars,
                isFontain: vm.fontain === undefined ? false : vm.fontain,
                isColored: vm.colored === undefined ? false : vm.colored,
                isMagic: vm.magic === undefined ? false : vm.magic,
                skretchPosition: vm.position === undefined ? 0 : vm.position,
            }
            for (let key in setting) {
                this.dataForProduct.append(`effects[${key}]`, setting[key]);
            }
            axios({
                method: 'POST',
                url: this.urlToBack,
                data: this.dataForProduct,
                headers: {
                    'headers': {'Content-Type': 'multipart/form-data'},
                }
            }).then(function (response) {
                vm.product = response.data.product
                console.log(vm.product)
                try {
                    parent.postMessage("createPurchase", "*")
                } catch (e) {
                    console.log('error')
                }
                const newParseLanguage = JSON.parse(vm.language);
                let title = newParseLanguage.content.txt.notification_success_apply_changes_shopify;

                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: title,
                    showConfirmButton: false,
                    showCloseButton: true,
                    timer: 8000,
                    toast: true,
                })
            })
        },
        apply() {
            let canApply= null;
            this.blocks.forEach((block) => {
                if (!block.inputText.trim()) {
                    block.error=this.parseLanguage.content.txt.field_required;
                    canApply = false;
                }
                else{
                    canApply = true;
                }
            });
            if(canApply) {
                const newParseLanguage = JSON.parse(this.language);
                let title = newParseLanguage.content.txt.confirm_text;
                Swal.fire({
                    title: title,
                    icon: 'question',
                    showCancelButton: true,
                    confirmButtonColor: '#FF5370',
                    cancelButtonColor: '#BCBABA',
                    confirmButtonText:this.parseLanguage.content.txt.yes,
                    cancelButtonText: this.parseLanguage.content.txt.no
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.confirmTextToPrasentCard();
                    }
                })
            }
        },
        confirmTextToPrasentCard(){
            let vm = this;
            vm.isOpacity = false;
            let shareBtn = $('#share')
            let discartBtn = $('#discart')
            if (shareBtn !== null) {
                shareBtn.removeClass('d-none')
                shareBtn.addClass('d-flex')
            }
            discartBtn.addClass('d-none')
            let saveBlocks = vm.blocks.map((block)=>{
                return {...block,
                    fontSize:vm.blocksForSave.find( ({ id }) => id === block.id).fontSize,
                    transform: vm.blocksForSave.find( ({ id }) => id === block.id).transform,
                    active: false,
                }
            })
            let setting = {
                text: saveBlocks === undefined ? "" : JSON.stringify(saveBlocks),
                isSkretch: vm.scratch === undefined ? false : vm.scratch,
                isBalloons: vm.balloon === undefined ? false : vm.balloon,
                isConfetti: vm.confetti === undefined ? false : vm.confetti,
                isFireworks: vm.fireworks === undefined ? false : vm.fireworks,
                isBubbles: vm.bubbles === undefined ? false : vm.bubbles,
                isStars: vm.stars === undefined ? false : vm.stars,
                isFontain: vm.fontain === undefined ? false : vm.fontain,
                isColored: vm.colored === undefined ? false : vm.colored,
                isMagic: vm.magic === undefined ? false : vm.magic,
                skretchPosition: vm.position === undefined ? 0 : vm.position,
            }
            for (let key in setting) {
                this.dataForProduct.append(`effects[${key}]`, setting[key]);
            }

            axios({
                method: 'POST',
                url: this.urlToBack,
                data: this.dataForProduct,
                headers: {
                    'headers': {'Content-Type': 'multipart/form-data'},
                }
            }).then(function (response) {
                vm.product = response.data.product
                try {
                    parent.postMessage("createPurchase", "*")
                } catch (e) {
                }
                const newParseLanguage = JSON.parse(vm.language);
                let title = newParseLanguage.content.txt.notification_success_apply_changes;
                if (vm.isInApiRender) {
                    title = newParseLanguage.content.txt.notification_success_apply_changes_shopify;
                }
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: title,
                    showConfirmButton: false,
                    showCloseButton: true,
                    timer: 8000,
                    toast: true,
                })
            })
        },
        share(urlToPostcard) {
            if (urlToPostcard.length) {
                    let $temp = $("<input>");
                    $("body").append($temp);
                    $temp.val(urlToPostcard).select();
                    document.execCommand("copy");
                    $temp.remove();
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: this.parseLanguage.content.txt.saved_to_clipboard,
                        showConfirmButton: false,
                        showCloseButton: true,
                        timer: 3500,
                        toast: true,
                    })
                    setTimeout(function () {
                        location.href = urlToPostcard;
                    }, 1000)
            }

        }
    },

}
</script>

<style lang="scss" scoped>
.ring{
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 14px;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    background-color: #353535;
    color: white;
}
.preview-text {
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #353535;
}
.textBiggerSmaller{
    margin: 0 5px;
}

.textBiggerSmaller span:nth-child(3){
    font-size: 15px;
}
.textBiggerSmaller span:nth-child(2){
    font-size: 12px;
}
.textBiggerSmaller span:nth-child(1){
    font-size: 9px;
}
.sourceSmallerFont{
    background-color: #ffffff;
    border: 1px solid #cdcdcd;
    border-radius: 5px;
    display: inline-block;
    color: black;
    font-size: 16px;
    text-align: center;
    padding: 0 2px;
    width: 24px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    transition: all 250ms linear;
    &:hover,
    &:focus{
        background-color: #cdcdcd;
    }

}
.inputs-container{
    background-color:  #ffffff66;
    padding: 10px;
    border:1px solid #D1D6D8;
    border-radius: 2px;
    max-height: 350px;
    overflow: auto;
    &::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}
.card {
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: center;
    padding: 0 10px;
    margin-bottom: 3px;
}

.text {
    position: absolute;
    text-align: center;
    max-width: 70%;
    height: 96%;
    line-height: 1.59;
    @media screen and (min-width: 576px) {
        line-height: 1.59;
        height: 93%;
    }
    @media screen and (min-width: 992px) {
        height: 100%;
        line-height: 1.67;
    }

}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 30px 0;

    @media screen and (min-width: 992px) {
        flex-direction: row;
        align-items: flex-start;
    }
}

.main-container {
    user-select: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 350px;
    height: 477px;
    margin-top: 0;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
//1.36
    @media screen and (min-width: 992px) {
        width: 400px;
        height: 545px;
        margin-bottom: 0;
        margin-left: 0;
        margin-right: 75px;

    }
}

.chouse-effect {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
}

.public {
    display: flex;
}

.btn-item {
    @media screen and (max-width: 576px) {
        width: 270px !important;
    }
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #353535;
    width: 370px !important;
    height: 51px !important;
    min-width: fit-content !important;
    background-color: #D1D6D8 !important;
    border: 1px solid !important;
    border-radius: 5px !important;
    border-color: #D1D6D8 !important;
    margin-bottom: 15px !important;
    transition: all 250ms linear !important;

    &:hover,
    &:focus {
        box-shadow: 0px 10px 20px rgba(124, 124, 124, 0.25);
    }

    &--outlined {

        background-color: transparent !important;
        transition: all 250ms linear !important;

        &:hover,
        &:focus {
            background-color: transparent !important;
            background-color: #D1D6D8 !important;
            box-shadow: 0px 0px 0px;
        }
    }

    &--submit {
        width: 100% !important;
    }
}

.public-btn-item .social {
    transform: scale(1);
    transition: all 250ms linear;
    color: #c78f7c;
    fill: currentColor;
}

.form-container {
    z-index: 1;
    height: 100%;
    max-width: 400px;
    width: 95%;
    @media screen and (min-width: 992px) {
        width: 50%;
    }
}

.input-item {
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    background-color: transparent;
    color: #353535;
    display: block;
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid;
    border-radius: 5px;
    border-color: #D1D6D8;
    padding: 15px 10px;

    &:hover,
    &:focus,
    &:active {
        outline: none;
        border-color: #353535;
    }
}

.opacity {
    opacity: 0.5;
}

.center {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-label {
    font-family: montserrat-medium;
    font-weight: 500;
    font-size: 12px;
    line-height: 17px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    color: #353535;
}

</style>
