<template>
  <img v-bind:src="src" alt="test card" class="image">
</template>

<script>

export default {
  name: "Card",
  props: {
    src: {
      type: String,
      // default: "/images/card1.svg"
    }
  },

}
</script>

<style lang="scss">
.image{
  object-fit: cover;
  z-index: 3;
  display: block;
  width: 100%;
  height: 100%;
}

</style>
