<template>
    <div v-if="blocks.length && inAdminUse" class="blocks-container">
    <DDR v-for="(block, index) in blocks"
         :key="index"
         :id="block.id"
         :active="block.active"
         :beforeActive="handleActive"
         v-model="block.transform"
         style="z-index:4;"
         :parent="true">

        <div class="blocks-container-text--admin" :style="{textAlign:block.textPosition}" :id="block.id" >
            <span v-bind:style="{ color: block.color, fontSize: block.fontSize, fontFamily: block.fontFamily, whiteSpace:'pre-line',}">{{block.inputText.trim()}}</span>
        </div>
    </DDR>
    </div>
    <div v-else-if="blocks.length && !inAdminUse" class="blocks-container">
        <DDR v-for="(block, index) in blocks"
             :key="index"
             :id="block.id"
             :active="block.active"
             v-model="block.transform"
             style="z-index:4;"
             :parent="true">

            <div class="blocks-container-text--user" :style="{textAlign:block.textPosition}" :id="block.id">
                <span v-if="block.inputText === ''" v-bind:style="{ color: block.color, fontSize: block.fontSize, fontFamily: block.fontFamily, whiteSpace:'pre-line' }">{{block.inputPlaceholder.trim()}}</span>
                <span v-else v-bind:style="{ color: block.color, fontSize: block.fontSize, fontFamily: block.fontFamily, whiteSpace:'pre-line' }">{{block.inputText.trim()}}</span>
            </div>
        </DDR>
    </div>
</template>

<script>
import DDR from 'yoyoo-ddr'
import 'yoyoo-ddr/dist/yoyoo-ddr.css'
export default {
    name:"TextBlock",
    components:{DDR},

    props:['blocks', 'inAdminUse'],

    methods:{
        handleActive(id) {
            this.$emit('active',id)
            return true
        },
    },
}
</script>
<style lang="scss">
.blocks-container {
    width: 100%;
    height: 100%;
    position:absolute;
    top:0;
    left: 0;
}
.blocks-container-text--admin{
    background:transparent;
    outline:1px solid #cdcdcd;
    width:100%;height:100%;
    word-break: break-word;
    line-height: 1;
}
.blocks-container-text--user{
    background:transparent;
    outline:none;
    width:100%;height:100%;
    word-break: normal;
    line-height: 1;
}
</style>
