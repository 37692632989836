<template lang="">
    <Particles
      id="tsparticles"
      :particlesInit="particlesInit"
      :particlesLoaded="particlesLoaded"
      :options="options"
    />
</template>
<script>
import { loadFull } from "tsparticles";
export default {
  name:'CustomFontain',
  data() {
    return {
      options: {
  // backgroundMode: {
  //   enable: true
  // },
  fpsLimit: 60,
  particles: {
    number: {
      value: 0
    },
    collisions: {
      enable: false
    },
    shape: {
      type: "circle"
    },
    opacity: {
      value: 0.5,
      random: false,
      animation: {
        enable: false,
        speed: 1,
        minimumValue: 0.1,
        sync: false
      }
    },
    size: {
      value: 15,
      random: {
        enable: true,
        minimumValue: 5
      },
      animation: {
        enable: false,
        speed: 40,
        minimumValue: 0.1,
        sync: false
      }
    },
    links: {
      enable: false
    },
    life: {
      duration: {
        sync: true,
        value: 5
      },
      count: 1
    },
    move: {
      enable: true,
      gravity: {
        enable: true
      },
      speed: 10,
      direction: "none",
      random: false,
      straight: false,
      outModes: {
        top: "none",
        default: "destroy",

      },
      trail: {
        enable: true,
        fillColor: "#ffffff",
        length: 10
      }
    }
  },
  interactivity: {
    detect_on: "canvas",
    events: {
      onHover: {
        enable: false,
        mode: "repulse",
        parallax: {
          enable: false,
          force: 60,
          smooth: 10
        }
      },
      onClick: {
        enable: true,
        mode: "push"
      },
      resize: true
    },
    modes: {
      grab: {
        distance: 400,
        line_linked: {
          opacity: 1
        }
      },
      bubble: {
        distance: 400,
        size: 40,
        duration: 2,
        opacity: 0.8
      },
      repulse: {
        distance: 200
      },
      push: {
        particles_nb: 4
      },
      remove: {
        particles_nb: 2
      }
    }
  },
  detectRetina: true,
  //  background: {
  //   color: {
  //     value: "#ffffff"
  //         },
  //   size: "auto 100%",
  //         repeat: "no-repeat",
  //         position: "center"
  // },
  emitters: {
    direction: "top",
    life: {
      count: 40,
      duration: 0.1,
      delay: 0.1
    },
    rate: {
      delay: 0.1,
      quantity: 1
    },
    size: {
      width: 0,
      height: 0
    },
    position: {
      x: 50,
      y: 100
    },
    particles: {
      bounce: {
        vertical: {
          value: 0.8,
          random: {
            enable: true,
            minimValue: 0.4
          }
        }
      },
      color: {
        value: ["#53d9d1", "#f27b9b", "#eb7132"]
      },
      links: {
        enable: false
      },
      opacity: {
        value: 0.5
      },
      move: {
        speed: 13,
        random: false
      }
    }
  }
}
    }
  },
  methods: {
    async particlesInit (engine) {
    await loadFull(engine)
    },
    async particlesLoaded(container){
      setTimeout(function () {
 	      container.destroy();
      }, 15000)
  }
  },
}
</script>
<style lang="scss">

</style>
