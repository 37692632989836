<template>
  <div class="container"
  :style="` backgroundImage: url(${urlBg});
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;`">
  <!-- <Loader v-if="loader"/> -->
    <template>
<!--      <p class="mb-2 main-text text-muted">Click on a button to open builder</p>-->
<!--      <p class="mb-4" style="z-index: 1;">-->
<!--        <a class="btn btn-primary" title="Customize your card" data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">-->
<!--          Let's start magic-->
<!--          <i class="fa fa-magic"></i>-->
<!--        </a>-->
<!--      </p>-->
<!--      <div class="collapse" id="collapseExample">-->
<!--        <div class="card card-body">-->
          <div class="block block--first mb-3 flex-column flex-lg-row z-index">
            <div class="d-flex flex-column mb-3 mb-xl-0">
              <p class="mb-2 main-text description text-muted">Click to load image for present card</p>
              <div class="d-flex align-items-center">
                <label for="image" class="btn btn-outline-primary me-2">Upload image
                  <i class="fa fa-picture-o"></i>
                </label>
                <input type="file" id="image" @change.prevent="downloadImage" hidden/>
                <span v-if="isAddingIm"><i class="fa fa-paperclip"></i></span>
              </div>
            </div>
            <div class="d-flex flex-column mb-3 mb-xl-0">
            <p class="mb-2 main-text description text-muted">Click to load background for page</p>
            <div class="d-flex align-items-center">
              <label for="background" class="btn btn-outline-primary me-2">Upload background
                <i class="fa fa-file-photo-o"></i>
              </label>
              <input type="file" id="background" @change.prevent="downloadBackground" hidden/>
              <span v-if="isAddingBg"><i class="fa fa-paperclip"></i></span>
            </div>
            </div>
            <div class="d-flex flex-column  mb-3 mb-xl-0">
            <p class="mb-2 main-text description text-muted">Click to load form for scratching</p>
            <div class="d-flex align-items-center">
              <label for="scratch" class="btn btn-outline-primary me-2">Upload scratch
                <i class="fa fa-pencil-square-o"></i>
              </label>
              <input type="file" id="scratch" @change.prevent="downloadSkratch" hidden/>
              <span v-if="isAddingSk"><i class="fa fa-paperclip"></i></span>
            </div>
            </div>
          </div>
          <div class="block mb-3 z-index">
              <p class="mb-2 main-text">Effects show up on background</p>
              <div class="block-controls-effect col-12 mb-3">
                <div class="form-check form-check--first radio radio-primary col-4 mb-1">
                  <input class="form-check-input" type="radio" id="Bubbles" value="Bubbles" v-model="picked" @input="useBubbles"/>
                  <label class="form-check-label" for="Bubbles">Bubbles</label>
                </div>
                <div class="form-check radio radio-primary col-4 mb-1">
                  <input type="radio" id="Stars" value="Stars" v-model="picked" @input="useStars"/>
                  <label for="Stars">Stars</label>
                </div>
                <div class="form-check radio radio-primary col-4 mb-1">
                  <input type="radio" id="Fontain" value="Fontain" v-model="picked" @input="useFontain"/>
                  <label for="Fontain">Fontain</label>
                </div>
                <div class="form-check radio radio-primary col-4 mb-1">
                  <input type="radio" id="Colored" value="Colored" v-model="picked" @input="useColored"/>
                  <label for="Colored">Colored</label>
                </div>
                <div class="form-check radio radio-primary col-4 mb-1">
                  <input type="radio" id="Magic" value="Magic" v-model="picked" @input="useMagic"/>
                  <label for="Magic">Magic</label>
                </div>
                <div class="form-check radio radio-primary col-4 mb-1">
                  <input type="radio" id="Fireworks" value="Fireworks" v-model="picked" @input="useFireworks"/>
                  <label for="Fireworks">Fireworks</label>
                </div>
            </div>
              <p class="mb-2 main-text description">Effects show up on card</p>
              <div class="block-controls-effect mb-3">
                  <button type="button" @click="useBalloons"  v-bind:class=" {active: isBalloons }"
                          class="btn btn-outline-primary me-1 mb-1 js-btn-effects-choose" id="balloons">Balloons
                  </button>
                  <button type="button" @click="useConfetti"  v-bind:class="{ active: isConfetti }" class="btn btn-outline-primary me-1 mb-1 js-btn-effects-choose" id="btnConfetti">Confetti
                  </button>
                  <Button type="button" @press="useSkretch" v-bind:class="{ active: isSkretch }" class="btn btn-outline-primary mb-1">Scratch</Button>
                  <div class="d-flex">
                      <div class="wrapper-updown me-1 justify-content-center">
                          <button @click="increment" class="updown">
                              <img src="/images/small-up.svg" alt="up">
                          </button>
                          <button @click="decrement" class="updown">
                              <img src="/images/small-down.svg" alt="down">
                          </button>
                      </div>
                      <p class="mb-2 main-text description text-muted">Try to move scratch zone up/down</p>
                  </div>
              </div>
            <div class="mb-1 d-flex align-items-center">
                <Button type="button" @press="reset" class="btn btn-primary col-12 col-sm-4 col-md-3 col-xl-2 me-2">Discard effects
                </Button>
                <p class="main-text text-muted">Discard all effects which were used</p>
            </div>
          </div>

<!--        </div>-->

<!--    </div>-->
        <div class="wrap-main pb-3">
          <div class="main-container" @click="noActiveBlock" >
          <TextBlock :blocks="blocks" @active="watchActive" inAdminUse="true">
          </TextBlock>
            <Card :src="urlCard"></Card>
            <Balloon v-if="isBalloons"></Balloon>
            <Skretch v-if="isSkretch" :isInBuilderUse="isInBuilderUse" :position="skretchPosition"
                    :image="urlSc"></Skretch>
            <Confetti v-if="isConfetti"></Confetti>
            <CustomParticles v-if="isFireworks"></CustomParticles>
            <CustomBubbles v-if="isBubbles"></CustomBubbles>
            <CustomStars v-if="isStars"></CustomStars>
            <CustomFontain v-if="isFontain"></CustomFontain>
            <CustomColored v-if="isColored"></CustomColored>
            <CustomMagic v-if="isMagic"></CustomMagic>
          </div>
            <DynamicInput :blocks="blocks" @addNewBlock="watchNewBlock" @removeBlock="deleteBlock"/>
      </div>
        <div class="block z-index">
            <h6>Select the duration of the link</h6>
            <p class="mb-2 text-muted">
                <i class="fa fa-exclamation"></i>
                The duration must be chosen, and it must be greater than 1</p>
            <div class="d-flex flex-column">
                <div class="form-check ps-3 mb-2">
                    <input class="form-check-input" v-model="newPermanent" type="checkbox" id="flexCheckChecked"
                           :checked="newPermanent">
                    <label class="form-check-label" for="flexCheckChecked">
                        Permanently?
                    </label>
                </div>
                <div class="mb-3">
                    <label for="duration" class="form-label">Duration in days</label>
                    <input type="number" v-model="newDurationInDays"
                           class="form-control digits js-duration-in-days description"
                           :disabled="newPermanent == true || newPermanent == 'true' || newPermanent == '1' || newPermanent == 1"
                           min="1" id="duration" placeholder="Duration in Days">
                    <span class="invalid-feedback" role="alert"></span>
                </div>
            </div>
        </div>
      <div class="d-flex flex-column flex-md-row align-items-center justify-content-center z-index mb-5">
        <div>
          <p class="mb-2 main-text description text-muted">Click to confirm changes</p>
          <Button type="button" class="btn btn-outline-primary mb-3 mb-md-0 me-md-4" @press="apply" data-bs-toggle="tooltip" data-bs-placement="top"
                title="Save Your Changes" style="z-index: 1;">Apply
          </Button>
        </div>
        <div>
            <div class="mb-2 main-text description text-muted position-relative">
                <img :src="'/images/global/info.svg'"
                     class="pe-1 cursor-info absolute-info-icon" alt="fan size"
                     data-bs-toggle="tooltip" data-bs-placement="top"
                     title="This button will become available after you click on 'Apply' button">
                <span>Click to see client page</span>
            </div>
          <Button type="button" class="btn btn-primary js-check-changes-btn" disabled @press="check" data-bs-toggle="tooltip" data-bs-placement="top"
                  title="See a preview of how the customer sees it" style="z-index: 1;">Check changes
          </Button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import TextBlock from "./blocks";
import Button from "./button.vue"
import Card from "./card.vue"
import Balloon from "./balloon.vue"
import Confetti from "./confetti.vue"
import Skretch from "./scretch.vue"
import Swal from "sweetalert2";
import Loader from "./loader.vue"
import CustomParticles from "./particles.vue"
import CustomBubbles from "./bubbles.vue"
import CustomStars from "./stars.vue"
import CustomFontain from "./fontain.vue"
import CustomColored from "./colored.vue"
import CustomMagic from "./magic.vue"
import {Tooltip} from "bootstrap";
import DynamicInput from "./dynamic-input";

new Tooltip(document.body, {
    selector: "[data-bs-toggle='tooltip']",
});
export default {

    name: 'Builder',
    components: {
        Button,
        Card,
        Balloon,
        Confetti,
        Skretch,
        Loader,
        CustomParticles,
        CustomBubbles,
        CustomStars,
        CustomFontain,
        CustomColored,
        CustomMagic,
        DynamicInput,
        TextBlock,
    },

    data() {
      return {
          blocks: [],
          picked: '',
          product: this.productId,
          newPermanent: this.permanent,
          newDurationInDays: this.durationInDays,
          isSkretch: false,
          isBalloons: false,
          isConfetti: false,
          isFireworks: false,
          isBubbles: false,
          isStars: false,
          isFontain: false,
          isColored: false,
          isMagic:false,
          urlCard: this.urlImage,
          urlBg: this.urlBackground,
          urlSc: this.urlScratch,
          isInBuilderUse: true,
          dataForProduct: new FormData(),
          skretchPosition: 0,
          loader: true,
          isAddingIm: false,
          isAddingBg: false,
          isAddingSk: false,
        }
    },
    props: [
        'productId',
        'permanent',
        'durationInDays',
        'urlToBack',
        'urlImage',
        'urlBackground',
        'urlScratch',
        'effects',
        'urlPreview',
    ],
  computed: {
        savedBlocks() {
            let parseText = JSON.parse(this.effects.text);
            if (parseText === "" || parseText === undefined){
                return [];
            }
            return parseText;
        },
        scratch() {
            if (this.effects.isSkretch === 'false' || this.effects.isConfetti === undefined) {
                return false;
            }
            return true;
        },
        balloon() {
            if (this.effects.isBalloons === 'false' || this.effects.isBalloons === undefined) {
                return false;
            }
            return true;
        },
        confetti() {
            if (this.effects.isConfetti === 'false' || this.effects.isConfetti === undefined) {
                return false;
            }
            return true;
      },
        fireworks() {
            if (this.effects.isFireworks === 'false' || this.effects.isFireworks === undefined) {
                return false;
            }
            return true;
    },
        bubbles() {
            if (this.effects.isBubbles === 'false' || this.effects.isBubbles === undefined) {
                return false;
            }
            return true;
    },
        stars() {
            if (this.effects.isStars === 'false' || this.effects.isStars === undefined) {
                return false;
            }
            return true;
    },
        fontain() {
            if (this.effects.isFontain === 'false' || this.effects.isFontain === undefined) {
                return false;
            }
            return true;
    },
        colored() {
            if (this.effects.isColored === 'false' || this.effects.isColored === undefined) {
                return false;
            }
            return true;
    },
        magic() {
            if (this.effects.isMagic === 'false' || this.effects.isMagic === undefined) {
                return false;
            }
            return true;
        },
        position() {
            if (this.effects.skretchPosition === '' || this.effects.skretchPosition === undefined) {
                return 0;
            }
            return Number(this.effects.skretchPosition);
    },

    },
    mounted() {
      window.addEventListener('load', () => setTimeout(() => {
          this.loader = !this.loader
      }, 1000));
      this.blocks = this.savedBlocks;
      this.isSkretch = this.scratch;
      this.isBalloons = this.balloon;
      this.isConfetti = this.confetti;
      this.isFireworks = this.fireworks;
      this.isBubbles = this.bubbles;
      this.isStars = this.stars;
      this.isFontain = this.fontain;
      this.isColored = this.colored;
      this.isMagic = this.magic;
      this.urlCard = this.urlImage;
      this.urlBg = this.urlBackground;
      this.urlSk = this.urlScratch;
      this.skretchPosition = this.position;
      this.picked = this.isFireworks&&'Fireworks'|| this.isBubbles&&'Bubbles'|| this.isStars&&'Stars' ||this.isFontain&&'Fontain'|| this.isColored&&'Colored' || this.isMagic&&'Magic'
    },
    methods: {
        noActiveBlock(e){
            if(e.target.tagName==='IMG'){
                this.blocks = this.blocks.map((block)=>{
                    block = { ...block, active: false };
                    return block;
                })
            }
        },
        watchActive(id){
            this.blocks = this.blocks.map((block) => {
                    if (block.id === id) {
                        block = { ...block, active: true }
                    } else if (block.active) {
                        block = { ...block, active: false }
                    }
                    return block})
        },
        watchNewBlock(newBlock){
            this.blocks.push(newBlock);
        },
        deleteBlock(index){
            this.blocks.splice(index, 1);
        },

      useSkretch() {
        this.isSkretch = !this.isSkretch;
      },
      useBalloons() {
        this.isBalloons = !this.isBalloons;
            if (this.isBalloons) {
                $('#btnConfetti').prop("disabled", true);
            }else {
              $('#btnConfetti').prop("disabled", false);
            }
      },
      useConfetti() {
        this.isConfetti = !this.isConfetti
        if (this.isConfetti) {
                $('#balloons').prop("disabled", true);
        } else {
              $('#balloons').prop("disabled", false);
            }
      },
      reset() {
        this.isSkretch = false;
        this.isBalloons = false;
        this.isConfetti = false;
        this.isFireworks = false;
        this.isBubbles = false;
        this.isStars = false;
        this.isFontain = false;
        this.isColored = false;
        this.isMagic = false;
        this.picked = '';
        $('.js-btn-effects-choose').prop("disabled", false)
      },
      useFireworks() {
        this.isFireworks = true;
        this.isBubbles = false;
        this.isStars = false;
        this.isFontain = false;
        this.isColored = false;
        this.isMagic = false;
      },
      useBubbles() {
        this.isFireworks = false;
        this.isBubbles = true;
        this.isStars = false;
        this.isFontain = false;
        this.isColored = false;
        this.isMagic = false;
      },
      useStars() {
        this.isFireworks = false;
        this.isBubbles = false;
        this.isStars = true;
        this.isFontain = false;
        this.isColored = false;
        this.isMagic = false;

      },
      useFontain() {
        this.isFireworks = false;
        this.isBubbles = false;
        this.isStars = false;
        this.isFontain = true;
        this.isColored = false;
        this.isMagic = false;
      },
      useColored() {
        this.isFireworks = false;
        this.isBubbles = false;
        this.isStars = false;
        this.isFontain = false;
        this.isColored = true;
        this.isMagic = false;
      },
      useMagic() {
        this.isFireworks = false;
        this.isBubbles = false;
        this.isStars = false;
        this.isFontain = false;
        this.isColored = false;
        this.isMagic = true;
      },
        canChangeFontSize(parentBlockWidth,childBlockWidth,parentBlockHeight,childBlockHeight){
            if (parentBlockWidth <= childBlockWidth || parentBlockHeight <= childBlockHeight) {
                return false
            } else {
                return true
            }
        },
      apply() {
          let vm = this;
          if (vm.newPermanent) {
              vm.newDurationInDays = 0;
          } else {
              if (vm.newDurationInDays < 1) {
                  Swal.fire({
                      icon: 'warning',
                      text: 'Duration must be greater than 0',
                      confirmButtonText: 'Ok'
                  })
                  return;
              }
          }
          this.blocks = this.blocks.map((block)=>{
              let parentBlockWidth = document.getElementById(block.id).offsetWidth;
              let childBlockWidth = document.getElementById(block.id).firstElementChild.offsetWidth;
              let parentBlockHeight = document.getElementById(block.id).offsetHeight;
              let childBlockHeight = document.getElementById(block.id).firstElementChild.offsetHeight;
              return {...block,
                  canTextGrow:this.canChangeFontSize(parentBlockWidth,childBlockWidth,parentBlockHeight,childBlockHeight),
                  active: false,
              }
          });
          let setting = {
            text: vm.blocks === undefined ? "" : JSON.stringify(vm.blocks),
            isSkretch: vm.isSkretch === undefined ? false : vm.isSkretch,
            isBalloons: vm.isBalloons === undefined ? false : vm.isBalloons,
            isConfetti: vm.isConfetti === undefined ? false : vm.isConfetti,
            isFireworks: vm.isFireworks === undefined ? false : vm.isFireworks,
            isBubbles: vm.isBubbles === undefined ? false : vm.isBubbles,
            isStars: vm.isStars === undefined ? false : vm.isStars,
            isFontain: vm.isFontain === undefined ? false : vm.isFontain,
            isColored: vm.isColored === undefined ? false : vm.isColored,
            isMagic: vm.isMagic === undefined ? false : vm.isMagic,
            skretchPosition: vm.skretchPosition === undefined ? 0 : vm.skretchPosition,
          }
          for (let key in setting) {
              this.dataForProduct.append(`effects[${key}]`, setting[key]);
          }
          this.dataForProduct.append('permanent', this.newPermanent);
          this.dataForProduct.append('duration_in_days', this.newDurationInDays);
          axios({
              method: 'POST',
              url: this.urlToBack,
              data: this.dataForProduct,
              headers: {
                  'headers': {'Content-Type': 'multipart/form-data'},
              }
          }).then(function (response) {
              vm.product = response.data.product
              $('.js-check-changes-btn').removeAttr('disabled');
              Swal.fire({
                  position: 'top-end',
                  icon: 'success',
                  title: 'Product Successfully Updated, You can Check you changes or return back to product lists',
                  showConfirmButton: false,
                  showCloseButton: true,
                  timer: 5000,
                  toast: true,
              })
          })
      },
      readURLI(image) {
          let reader = new FileReader();
          reader.onload = function (e) {
              this.urlImage = e.target.result;
          };
          reader.readAsDataURL(image);
      },
      readURLB(image) {
          let reader = new FileReader();
          reader.onload = function (e) {
              this.urlBackground = e.target.result;
          };
          reader.readAsDataURL(image);
      },
      readURLS(image) {
          let reader = new FileReader();
          reader.onload = function (e) {
              this.urlScratch = e.target.result;
          };
          reader.readAsDataURL(image);
      },
      downloadImage(e) {
          this.readURLI(e.target.files[0]);
          this.dataForProduct.append('file', e.target.files[0], e.target.files[0].name);
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
              this.urlCard = reader.result
          };
          reader.readAsDataURL(file);
          this.isAddingIm = true;
      },
      downloadBackground(e) {
          this.readURLB(e.target.files[0],);
          this.dataForProduct.append('background', e.target.files[0], e.target.files[0].name);
          const file = e.target.files[0];
          const reader = new FileReader();
          reader.onloadend = () => {
              this.urlBg = reader.result
          };
          reader.readAsDataURL(file);
          this.isAddingBg = true;
      },
        downloadSkratch(e) {
            this.readURLS(e.target.files[0]);
            this.dataForProduct.append('scratch', e.target.files[0], e.target.files[0].name);
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                this.urlSc = reader.result
            };
            reader.readAsDataURL(file);
            this.isAddingSk = true;
        },

        check() {
            window.open(
                `${this.urlPreview}`,
                '_blank'
            );
        },
        increment() {
            this.skretchPosition += 10;
        },
        decrement() {
            this.skretchPosition -= 10;
        }
    },
}
</script>

<style lang="scss" scoped>
  #duration::placeholder{
  font-size: 13px !important;
  line-height: 1.7 !important;
  letter-spacing: 0.7px !important;
  }
.btn-primary:focus{
  border-color: #7271fe !important;
  color: white !important;
  background-color: #7271fe !important;
}
.btn-outline-primary:focus{
  border-color: #7271fe !important;
  color: #7271fe !important;
  background-color: transparent !important;
}
.btn-outline-primary:disabled{
  color:#6c757d;
  border-color: #6c757d;
}

.z-index{
  z-index:4;
}
.description{
  max-width: 200px;
}
.btn{
  min-width: 160px;
}
.container {
  padding: 0;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    flex-direction: column;
    @media screen and (min-width: 1200px) {
        /* flex-direction: row; */
    }
}

.wrap-main {
  background-color: transparent;
   display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    flex-direction: column;
    border-bottom: 1px solid #eeeeee;
    @media screen and (min-width: 1200px){
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.absolute-info-icon {
    position: absolute;
    top: 0;
    right: -15px;
    z-index: 1;
}

.main-container {
  user-select: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 545px;
    //@media screen and (min-width: 576px) {
    //    width: 400px;
    //    height: 545px;
    //}
    //@media screen and (min-width: 992px) {
    //    min-width: 400px;
    //    min-height: 545px;
    //    max-height: 545px;
    //    max-width: 400px;
    //}
}

.chouse-effect {
    max-width: 50%;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 20px;
    padding: 20px 20px;
    background-color: #f6f7fb;
    @media screen and (max-width: 1199px) {
    max-width: 100%;
  }
  /* @media screen and (min-width: 576px) {
    max-width: 50%;
  } */

}

.block {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;
    &--first{
      flex-direction: row;
      justify-content: space-between;
    }
    &--first .btn{
      min-width: 204px;
    }
}

.btn-primary:focus.active, .btn-outline-primary:focus.active {
    background-color: #403efe !important;
    border-color: #403efe !important;
    color: white !important;
}
.not{
    border-color: #7271fe !important;
    color: #7271fe !important;
    background-color: transparent !important;
}

.wrapper-updown {
    display: inline-flex;
    flex-direction: column;
    margin-right: 10px;
}

.block-controls-effect {
    display: flex;
    /* align-items: flex-start; */
    flex-wrap: wrap;
}

.updown {
    background-color: transparent;
    border: none;
    padding-right: 0;
    padding-left: 0;
    width: 20px;
    height: 18px;
    display: flex;
    align-items: center;
}

.mr-10 {
    margin-right: 10px;
}
</style>
