<template>
  <div class="container-confetti">
    <canvas id="confetti"></canvas>
  </div>
</template>

<script>
import confettiEffect from "../effects/confetti.js"
export default {
  name: "Confetti",
  mounted() {
    window.addEventListener('load', () => {
        setTimeout(() => {
            confettiEffect();
        }, 300)
    })
    confettiEffect();
  },
}
</script>
<style lang="scss" scoped>
#confetti {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 4;
}
.container-confetti{
  width: 100%;
  height: 100%;
  position: absolute;
}
</style>
