<template>
    <div v-if="!isInBuilderUse && window.width >= 992" class="container coin" id="js-container"
         v-bind:style="{ bottom: position + 'px'}">
        <canvas class="canvas " id="js-canvas"></canvas>
    </div>

    <div v-else-if="!isInBuilderUse && window.width <= 992" class="container coin" id="js-container"
         v-bind:style="{ bottom: position*0.75 + 'px'}">
        <canvas class="canvas " id="js-canvas"></canvas>
    </div>

    <div v-else-if="isInBuilderUse" class="container coin" id="js-container"
         v-bind:style="{ bottom: position + 'px'}">
        <canvas class="canvas " id="js-canvas"></canvas>
    </div>
</template>

<script>
import skretchingEffect from "../card.js"

export default {
    name: "Skretch",
    data() {
        return {
            window: {
                width: 0,
                height: 0
            },
        }
    },
    created() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    mounted() {
        if (this.isInBuilderUse) {
            skretchingEffect(_, this.image);
        } else {
           skretchingEffect(this, this.image);

        }
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize);
    },
    props: {
        isInBuilderUse: {
            type: Boolean,
        },
        isOpacity: {
            type: Boolean,
        },
        position: {
            type: Number,
        },
        image: {
            type: String,
            required: false,
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
    }

}
</script>

<style lang="scss" scoped>
.container {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    margin: 0 auto;
    padding: 0 !important;
    z-index: 5 !important;
}

.coin {
    cursor: url("/images/1.svg") 6 6,
    default;
}

.new-coin {
    cursor: url("/images/2.svg") 6 6,
    default;
}

.canvas {
    z-index: 9;
    position: absolute;
    width: 100%;
    height: 100%;
}

</style>
