function random(num) {
  return Math.floor(Math.random()*num)
}
function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
function getRandomStyles() {
  // const r = random(255);
  // const g = random(255);
  // const b = random(255);
  const r = getRandomIntInclusive(210, 232);
  const g = getRandomIntInclusive(188, 218);
  const b = getRandomIntInclusive(181, 209);
  const mt = random(200);
  const ml = random(50);
  const dur = random(5)+10;
  return `
  background-color: rgb(${r},${g},${b});
  color: rgba(${r},${g},${b}); 
  
  margin: ${mt}px 0 0 ${ml}px;
  animation: float ${dur}s ease-in infinite
  `
}
function createBalloons(num) {
  const balloonContainer = document.getElementById("balloon-container")
  for (let i = num; i > 0; i--) {
  const balloon = document.createElement("div");
    balloon.className = "balloon";
    balloon.style.cssText = getRandomStyles();
    balloonContainer.append(balloon);
  }
}

export default function() {
  createBalloons(25);
}